import { Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { graphql } from 'gatsby'
import React, { useMemo } from 'react'
import { Layout } from '../../../components/Layout'
import { Link } from '../../../components/Link'
import { HeroImage } from '../../../components/MarriageRings/HeroImage'
import {
    RingGallery,
    convertMarriageRing,
} from '../../../components/RingGallery'
import { SEO } from '../../../components/SEO'
import { Txt } from '../../../components/Txt'
import type { MarriageRingsByDesignQuery } from '../../../../types/graphql-types'
import type { PageProps } from 'gatsby'

export const Head = () => (
    <SEO
        title="安い結婚指輪・激安マリッジリングをお好みのデザインから探せる｜ミスプラチナ"
        description="シンプルで人気のストレートやウェーブ、ダイヤつきのエタニティデザインの中から、結婚指輪（マリッジリング）が業界最安値（1万円台から）で購入いただけます。"
        page="marriagering/design"
    />
)

const IndexPage: React.FC<PageProps<MarriageRingsByDesignQuery>> = ({
    data,
}) => {
    const muiTheme = useTheme()
    const straightRings = useMemo(() => {
        return data.straightRings.edges.map((edge) => {
            return convertMarriageRing(edge.node)
        })
    }, [data])
    const waveRings = useMemo(() => {
        return data.waveRings.edges.map((edge) => {
            return convertMarriageRing(edge.node)
        })
    }, [data])
    const diamondRings = useMemo(() => {
        return data.diamondRings.edges.map((edge) => {
            return convertMarriageRing(edge.node)
        })
    }, [data])

    return (
        <Layout
            breadcrumbs={[
                { name: '安い結婚指輪・マリッジリング', path: 'marriagering/' },
                { name: 'デザインから探す' },
            ]}
        >
            <Grid container spacing={0} component="main">
                <Grid item xs={12}>
                    <HeroImage />
                </Grid>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <TitleWrapper>
                        <H1>
                            <Txt size="l">デザイン</Txt>
                            <Txt size="s">から結婚指輪を探す</Txt>
                        </H1>
                        <Description theme={muiTheme}>
                            <Txt size="xs">
                                ミスプラチナでは格安でデザイン豊富な結婚指輪を取り揃えております
                            </Txt>
                        </Description>
                    </TitleWrapper>
                </Grid>
                <Grid item xs={12}>
                    <CategorizeRings>
                        <H2>
                            <Txt size="l">ストレート</Txt>
                            <Txt size="s">デザイン</Txt>
                        </H2>
                        <Description2>
                            <Txt size="s">
                                シンプルで定番のストレートデザインのマリッジリング。
                            </Txt>
                            <br />
                            <Txt size="s">
                                オーソドックスなデザインなので男性にも人気のデザインです。
                            </Txt>
                        </Description2>
                        <RingGallery rings={straightRings} />
                        <MoreLinkWrapper>
                            <Link to="/marriagering/design/straight/">
                                ストレートデザインをもっと見る
                            </Link>
                        </MoreLinkWrapper>
                    </CategorizeRings>
                    <CategorizeRings>
                        <H2>
                            <Txt size="l">ウェーブ</Txt>
                            <Txt size="s">デザイン</Txt>
                        </H2>
                        <Description2>
                            <Txt size="s">
                                ファッション性の高いウェーブデザインはS字に波打ったラインで優しい印象を与えてくれます。
                                女性に人気のデザインです。
                            </Txt>
                        </Description2>
                        <RingGallery rings={waveRings} />
                        <MoreLinkWrapper>
                            <Link to="/marriagering/design/wave/">
                                ウェーブデザインをもっと見る
                            </Link>
                        </MoreLinkWrapper>
                    </CategorizeRings>
                    <CategorizeRings>
                        <H2>
                            <Txt size="l">メレダイヤ・エタニティ</Txt>
                            <Txt size="s">デザイン</Txt>
                        </H2>
                        <Description2>
                            <Txt size="s">
                                小さなダイヤの粒が埋め込まれたメレダイヤデザインはエタニティリングとも呼ばれ、華やかさとファッション性を伴う注目のデザインです。
                            </Txt>
                        </Description2>
                        <RingGallery rings={diamondRings} />
                        <MoreLinkWrapper>
                            <Link to="/marriagering/design/diamond/">
                                メレダイヤ・エタニティデザインをもっと見る
                            </Link>
                        </MoreLinkWrapper>
                    </CategorizeRings>
                </Grid>
            </Grid>
        </Layout>
    )
}

export const query = graphql`
    query MarriageRingsByDesign {
        straightRings: allMarriageringsJson(
            filter: { design: { eq: "straight" } }
            limit: 5
            sort: { fields: femalePrice, order: ASC }
        ) {
            edges {
                node {
                    code
                    name
                    material
                    femalePrice
                    malePrice
                    images
                }
            }
        }
        waveRings: allMarriageringsJson(
            filter: { design: { eq: "wave" } }
            limit: 5
            sort: { fields: femalePrice, order: ASC }
        ) {
            edges {
                node {
                    code
                    name
                    material
                    femalePrice
                    malePrice
                    images
                }
            }
        }
        diamondRings: allMarriageringsJson(
            filter: { design: { eq: "diamond" } }
            limit: 5
            sort: { fields: femalePrice, order: ASC }
        ) {
            edges {
                node {
                    code
                    name
                    material
                    femalePrice
                    malePrice
                    images
                }
            }
        }
    }
`

const TitleWrapper = styled('div')({
    padding: '20px',
})
const H1 = styled('h1')({
    margin: '0 0 8px',
    lineHeight: 1,
})
const H2 = styled('h2')({
    margin: '0 0 8px',
    lineHeight: 1,
    padding: '10px',
    borderBottom: '1px solid #aaaaaa',
})
const Description = styled('p')(({ theme }) => ({
    margin: '0 0 20px',
    color: theme.palette.text.secondary,
}))
const Description2 = styled('p')({
    margin: '10px 10px 16px',
})
const CategorizeRings = styled('section')({
    margin: '10px 10px 28px',
})
const MoreLinkWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'end',
    margin: '20px 10px',
})

export default IndexPage
